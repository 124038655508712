import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useCashRegisterPermissions = function (user) {
    var hasPermissionListCashRegister = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CASH_DESK_PAYMENT, MethodCode.LIST);
    var hasPermissionEditCashRegister = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CASH_DESK_PAYMENT, MethodCode.EDIT);
    var hasPermissionViewCashRegister = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CASH_DESK_PAYMENT, MethodCode.VIEW);
    var hasPermissionDeleteCashRegister = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CASH_DESK_PAYMENT, MethodCode.DELETE);
    var hasPermissionCreateCashRegister = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CASH_DESK_PAYMENT, MethodCode.CREATE);
    return {
        hasPermissionListCashRegister: hasPermissionListCashRegister,
        hasPermissionEditCashRegister: hasPermissionEditCashRegister,
        hasPermissionViewCashRegister: hasPermissionViewCashRegister,
        hasPermissionDeleteCashRegister: hasPermissionDeleteCashRegister,
        hasPermissionCreateCashRegister: hasPermissionCreateCashRegister,
    };
};
export default useCashRegisterPermissions;
